import PageLayout from '@global/page-layout';
import React from 'react';
import Signup from '@containers/Signup';

const SignupPage = () => (
  <PageLayout title="Sign up">
    <Signup />
  </PageLayout>
);

export default SignupPage;
