import styled from 'styled-components';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 300px;
  height: 35px;
  background-color: #5995ef;
  color: #fff;
  border-radius: 3px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  color: #808b96;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
`;

const Input = styled.input`
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  height: 51px;
  border: ${(props) => props.border || '1px solid #D6DCE1'};
  background-color: #fff;
  margin: 8px 0;
  padding: 16px;
  font-size: 16px;
  ::placeholder {
    font-family: 'GT Walsheim Pro';
    vertical-align: middle;
    opacity: 0.3;
    color: #808b96;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
  }
`;

const ErrorMessage = styled.span`
  color: #ff0000;
  font-size: 14px;
`;

export { Form, Button, Input, Label, FormItem, ErrorMessage };
