import React from 'react';
import { StyledContainer, StyledSection, Heading } from '@styled/components';
import { useStaticQuery, graphql } from 'gatsby';
import { Formik } from 'formik';
import {
  Form,
  Input,
  Button,
  Label,
  FormItem,
  ErrorMessage,
} from './signup.style';
import { SignupSchema } from './signupSchema';

const Signup = () => {
  const data = useStaticQuery(graphql`
    query allSignupSectionJson {
      signupSectionJson {
        title
      }
    }
  `);

  const { title } = data.signupSectionJson;

  return (
    <StyledSection>
      <StyledContainer>
        <Heading width="100%">{title}</Heading>
      </StyledContainer>
      <StyledContainer>
        <Formik
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormItem>
                <Label>First name</Label>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  border={
                    touched.firstName && errors.firstName && '1px solid red'
                  }
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                />
                {touched.firstName && errors.firstName && (
                  <ErrorMessage color="red">{errors.firstName}</ErrorMessage>
                )}
              </FormItem>
              <FormItem>
                <Label>Surname</Label>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  border={
                    touched.lastName && errors.lastName && '1px solid red'
                  }
                  type="text"
                  name="lastName"
                  placeholder="Enter your surname"
                />
                {touched.lastName && errors.lastName && (
                  <ErrorMessage color="red">{errors.lastName}</ErrorMessage>
                )}
              </FormItem>
              <FormItem>
                <Label>Email</Label>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  border={touched.email && errors.email && '1px solid red'}
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                />
                {touched.email && errors.email && (
                  <ErrorMessage color="red">{errors.email}</ErrorMessage>
                )}
              </FormItem>

              <FormItem>
                <Label>Password</Label>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  border={
                    touched.password && errors.password && '1px solid red'
                  }
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                />
                {touched.password && errors.password && (
                  <ErrorMessage color="red">{errors.password}</ErrorMessage>
                )}
              </FormItem>
              <FormItem>
                <Label>Password</Label>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  border={
                    touched.confirmPassword &&
                    errors.confirmPassword &&
                    '1px solid red'
                  }
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <ErrorMessage color="red">
                    {errors.confirmPassword}
                  </ErrorMessage>
                )}
              </FormItem>

              <Button type="submit">Submit</Button>
            </Form>
          )}
        />
      </StyledContainer>
    </StyledSection>
  );
};

export default Signup;
